import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1336 2127 c-38 -27 2 -95 42 -71 24 16 28 55 6 71 -10 7 -21 13 -24
13 -3 0 -14 -6 -24 -13z"/>
<path d="M1032 2101 c-23 -14 -10 -46 18 -46 16 0 26 6 28 19 3 16 -12 36 -28
36 -3 0 -11 -4 -18 -9z"/>
<path d="M944 2015 c-7 -18 3 -35 21 -35 15 0 24 19 18 38 -6 17 -32 15 -39
-3z"/>
<path d="M1366 1967 c-8 -12 -14 -41 -13 -64 2 -73 -51 -104 -119 -69 -31 17
-57 8 -74 -24 -12 -23 -3 -45 29 -74 41 -38 25 -91 -36 -117 -73 -31 -79 -104
-10 -133 22 -9 43 -30 60 -58 30 -52 80 -98 140 -130 36 -20 57 -23 142 -23
92 0 104 2 153 29 66 37 117 92 149 163 22 47 24 65 21 141 -4 107 -29 163
-105 232 -49 45 -122 80 -165 80 -11 0 -35 16 -55 35 -44 43 -94 48 -117 12z
m112 -59 c2 -4 15 -8 28 -8 14 0 22 -4 19 -10 -4 -6 7 -10 25 -10 47 0 101
-33 158 -95 38 -42 52 -64 52 -86 0 -18 4 -28 10 -24 6 4 10 -23 10 -74 0 -47
-4 -81 -10 -81 -5 0 -10 -9 -10 -20 0 -27 -40 -88 -80 -123 -30 -25 -49 -36
-123 -66 -34 -14 -124 -15 -132 -1 -3 6 -16 10 -27 10 -39 0 -158 87 -158 115
0 7 -25 35 -55 63 -53 50 -64 82 -28 82 8 0 12 4 9 9 -3 5 8 14 24 21 17 7 30
19 30 27 0 8 4 12 8 9 5 -3 8 15 8 39 -1 25 -3 45 -6 45 -9 0 -33 61 -26 67 3
3 35 6 72 7 75 2 94 16 94 71 0 19 9 42 23 57 l22 25 30 -21 c17 -11 32 -24
33 -28z"/>
<path d="M1460 1783 c-14 -3 -41 -22 -62 -42 -32 -33 -37 -45 -42 -102 -13
-137 -15 -189 -6 -184 4 3 11 -6 14 -19 6 -25 38 -42 65 -37 8 1 35 23 60 47
31 31 50 42 63 38 10 -3 18 -1 18 5 0 6 4 11 10 11 13 0 83 71 76 78 -3 3 -2
10 3 16 28 34 4 113 -46 157 -31 28 -101 42 -153 32z m117 -46 c39 -21 67 -78
59 -122 -9 -46 -66 -95 -111 -95 -35 0 -35 0 -35 44 0 39 3 45 23 48 14 2 23
11 25 26 3 18 -1 22 -22 22 -34 0 -40 -13 -41 -95 -1 -38 -5 -80 -9 -93 -8
-23 -60 -57 -75 -48 -4 3 -7 59 -6 124 1 96 4 124 18 146 39 59 110 76 174 43z"/>
<path d="M1242 1930 c-27 -25 -28 -51 -4 -73 26 -24 68 -21 86 5 21 30 20 44
-4 68 -26 26 -50 25 -78 0z"/>
<path d="M1044 1896 c-12 -31 0 -61 27 -64 33 -4 53 20 45 53 -5 20 -13 25
-37 25 -16 0 -32 -6 -35 -14z"/>
<path d="M787 1854 c-4 -4 -7 -18 -7 -31 0 -18 5 -23 26 -23 22 0 25 4 22 27
-3 27 -26 41 -41 27z"/>
<path d="M930 1816 c0 -30 30 -43 47 -21 16 23 5 45 -23 45 -19 0 -24 -5 -24
-24z"/>
<path d="M1102 1734 c-45 -31 -22 -104 32 -104 22 0 56 36 56 60 0 22 -33 60
-53 60 -7 0 -23 -7 -35 -16z"/>
<path d="M955 1620 c-37 -41 24 -97 63 -58 17 17 15 44 -4 62 -21 21 -37 20
-59 -4z"/>
<path d="M740 1196 c0 -25 5 -46 10 -46 6 0 10 4 10 9 0 5 12 12 26 15 20 5
25 12 22 34 -2 22 -9 28 -35 30 -33 3 -33 3 -33 -42z"/>
<path d="M822 1195 l0 -45 89 2 c70 2 89 5 89 17 0 11 3 11 18 -2 24 -22 50
-24 32 -2 -9 10 -9 15 -1 15 17 0 13 47 -5 54 -9 3 -27 6 -40 6 -23 0 -24 -3
-18 -42 5 -41 5 -42 -11 -23 -9 11 -18 30 -22 43 -8 32 -18 27 -35 -16 -14
-36 -18 -39 -47 -34 -38 5 -40 19 -3 25 l27 4 -27 2 c-38 2 -35 21 3 21 17 0
28 4 24 10 -3 6 -21 10 -39 10 -32 0 -33 -1 -34 -45z m218 10 c0 -8 -9 -15
-20 -15 -11 0 -20 7 -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z"/>
<path d="M1083 1233 c-23 -9 -14 -39 15 -50 l27 -11 -29 -1 c-22 -1 -27 -4
-19 -12 6 -6 21 -9 34 -7 32 4 33 42 2 46 -13 2 -23 8 -23 14 0 6 10 8 25 4
27 -7 35 8 9 18 -18 7 -23 7 -41 -1z"/>
<path d="M1162 1231 c-8 -5 -12 -22 -10 -42 3 -30 6 -34 32 -34 16 0 31 5 33
12 3 8 -6 11 -23 10 -19 -1 -27 3 -30 16 -3 23 22 33 41 17 12 -10 15 -10 15
3 0 23 -34 34 -58 18z"/>
<path d="M1230 1195 c0 -25 5 -45 10 -45 6 0 10 9 10 20 0 13 7 20 20 20 13 0
20 -7 20 -20 0 -19 6 -20 104 -18 58 1 103 5 101 9 -3 3 9 9 25 13 24 4 30 11
30 31 0 18 -7 26 -25 31 -43 11 -57 -1 -50 -43 6 -36 5 -37 -10 -18 -9 11 -18
30 -22 43 -7 31 -16 27 -34 -17 -15 -34 -19 -38 -48 -33 -38 5 -40 19 -3 25
l27 4 -27 2 c-38 2 -35 21 3 21 18 0 28 4 24 10 -4 6 -26 9 -51 8 -34 -2 -44
-7 -44 -20 0 -11 -8 -18 -20 -18 -13 0 -20 7 -20 20 0 11 -4 20 -10 20 -5 0
-10 -20 -10 -45z m90 -6 c0 -17 -4 -28 -10 -24 -5 3 -10 17 -10 31 0 13 5 24
10 24 6 0 10 -14 10 -31z m210 16 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0
8 9 15 20 15 11 0 20 -7 20 -15z"/>
<path d="M1560 1195 c0 -45 0 -45 35 -45 19 0 35 5 35 10 0 6 -11 10 -25 10
-31 0 -33 17 -2 23 l22 4 -22 2 c-31 2 -29 21 2 21 14 0 25 5 25 10 0 6 -16
10 -35 10 -35 0 -35 0 -35 -45z"/>
<path d="M1647 1234 c-16 -16 -6 -43 21 -52 l27 -10 -27 -1 c-16 -1 -28 -5
-28 -11 0 -15 57 -12 62 3 8 20 -2 37 -23 37 -10 0 -19 5 -19 11 0 6 9 8 21 4
15 -5 20 -3 17 6 -5 15 -40 24 -51 13z"/>
<path d="M1710 1230 c0 -5 7 -10 15 -10 10 0 15 -11 15 -35 0 -19 5 -35 10
-35 6 0 10 16 10 35 0 19 5 35 10 35 6 0 10 5 10 10 0 6 -16 10 -35 10 -19 0
-35 -4 -35 -10z"/>
<path d="M1790 1196 c0 -46 0 -46 35 -46 29 0 36 4 41 27 9 35 -7 57 -45 61
-31 3 -31 3 -31 -42z m58 -4 c3 -17 -2 -22 -17 -22 -16 0 -21 6 -21 26 0 33
33 30 38 -4z"/>
<path d="M1901 1222 c-6 -11 -15 -31 -20 -44 -8 -22 -7 -23 30 -23 44 0 46 5
20 54 -18 33 -19 34 -30 13z"/>
<path d="M1940 1230 c0 -5 7 -10 15 -10 10 0 15 -11 15 -35 0 -19 5 -35 10
-35 6 0 10 16 10 35 0 19 5 35 10 35 6 0 10 5 10 10 0 6 -16 10 -35 10 -19 0
-35 -4 -35 -10z"/>
<path d="M2020 1203 c-8 -21 -16 -40 -17 -43 -2 -3 17 -5 41 -6 l44 -1 -21 44
c-11 23 -24 43 -27 43 -3 0 -12 -17 -20 -37z"/>
<path d="M1252 1090 c0 -17 4 -28 9 -25 5 4 9 13 9 21 0 8 5 14 10 14 6 0 10
5 10 10 0 6 -8 10 -18 10 -14 0 -19 -8 -20 -30z"/>
<path d="M1345 1110 c-4 -6 -1 -18 7 -27 11 -15 11 -16 -7 -9 -16 6 -17 5 -8
-5 10 -10 15 -9 28 1 14 11 13 14 -2 27 -17 14 -17 14 0 9 11 -3 16 -2 12 4
-8 13 -22 13 -30 0z"/>
<path d="M1410 1105 c-7 -8 -10 -22 -6 -30 6 -17 36 -21 36 -4 0 5 -4 8 -9 4
-16 -10 -21 12 -6 25 12 10 16 9 19 -1 14 -43 14 -44 15 -26 1 19 21 24 22 5
0 -7 4 -4 9 7 8 19 9 19 9 -2 1 -15 7 -23 19 -22 10 0 12 3 5 6 -18 7 -16 23
2 23 8 0 15 -5 15 -11 0 -5 5 -7 11 -3 6 3 15 0 20 -7 6 -10 9 -6 9 14 0 29
-9 34 -28 15 -9 -9 -12 -8 -12 5 0 9 -7 17 -15 17 -8 0 -15 -4 -15 -9 0 -10
-55 -9 -66 2 -11 11 -20 9 -34 -8z"/>
<path d="M1597 1114 c-4 -4 -7 -16 -7 -26 0 -16 3 -17 21 -8 11 6 19 16 16 23
-4 14 -21 20 -30 11z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
